import React from "react"
import styled from "styled-components"
import { ContentWrapper } from "./Layout"
import { typeScale, breakpoint, perfectFourth } from "../utils"
import { HeaderOne } from "./Headers"

const HeroSecondary = ({ title, subtitle }) => {
  return (
    <ContentWrapper>
      <HeroWrapper>
        <TextWrapper>
          <div className="flow">
            <HeaderOne>
              {title}
              <span>.</span>
            </HeaderOne>
            <p>{subtitle}</p>
          </div>
        </TextWrapper>
        <ImageWrapper></ImageWrapper>
      </HeroWrapper>
    </ContentWrapper>
  )
}

export const HeroWrapper = styled.section`
  display: grid;
  grid-gap: ${perfectFourth[200]};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  @media ${breakpoint.md} {
    grid-template-columns: 1fr;
  }
`
export const TextWrapper = styled.div`
  padding-inline-start: ${perfectFourth[300]};
  padding-block-start: ${perfectFourth[500]};
  padding-inline-end: ${perfectFourth[600]};
  @media ${breakpoint.md} {
    padding-inline-end: ${perfectFourth[300]};
  }
  p {
    font-size: ${typeScale.subtitle};
    @media ${breakpoint.md} {
      text-align: center;
    }
  }
  .button-wrapper {
    @media ${breakpoint.md} {
      text-align: center;
    }
  }
`

export const ImageWrapper = styled.div`
  overflow: hidden;
`

export default HeroSecondary
