import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import HeroSecondary from "../components/HeroSecondary"
import { DotsFullWidth, InsideWrapper } from "../components/Layout"
import SEO from "../components/seo"
import { HeaderTwo, HeaderThree } from "../components/Headers"
import Link from "../components/Link"
import {
  perfectFourth,
  breakpoint,
  typeScale,
  navy,
  borderRadius,
  boxShadow,
} from "../utils"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const Academy = () => {
  const image = useStaticQuery(getImages)
  return (
    <>
      <SEO title="Academy" />
      <Layout>
        <HeroSecondary
          title="CSS Academy"
          subtitle="If you’re ready to dive deep and master one aspect of CSS a course, book, or event at a time, you’re in the right place."
        />
        <DotsFullWidth>
          <InsideWrapper>
            <div className="flow">
              <HeaderTwo>
                Get confident with CSS in under an hour<span>.</span>
                <TextWrapper>
                  <div className="flow">
                    <HeaderThree>
                      Learn the basics of CSS over your lunch break
                    </HeaderThree>
                    <p>
                      Learn the foundational concepts of CSS in under an hour
                      with 10 bite-sized videos.
                    </p>
                    <ButtonWrapper>
                      <Link
                        primary={true}
                        link={
                          "https://store.cssacademy.com/learn-css-over-lunch"
                        }
                        title={"Learn More"}
                        color={"red"}
                        arrow={"true"}
                      />
                    </ButtonWrapper>
                  </div>
                </TextWrapper>
              </HeaderTwo>
            </div>
          </InsideWrapper>
        </DotsFullWidth>
        <InsideWrapper>
          <div className="flow">
            <HeaderTwo>
              Courses, e-books, cheatsheets, and more<span>.</span>
            </HeaderTwo>
            <Offering>
              <div className="offering__image">
                <Image
                  fluid={image.logical.childImageSharp.fluid}
                  alt="The Ultimate Guide to Logical Properties ebook where you can learn everything you need to know about logical properties and values."
                />
              </div>
              <div className="offering__content-wrapper">
                <p className="offering__title">
                  The Ultimate Guide to Logical Properties
                </p>
                <p className="offering__subtitle">
                  Learn everything you need to know about Logical Properties and
                  Values.
                </p>
                <div className="offering__link">
                  <Link
                    primary={true}
                    link={"https://store.cssacademy.com/logical-properties"}
                    title={"Learn More"}
                    color={"teal-light"}
                    arrow={"true"}
                  />
                </div>
              </div>
            </Offering>
            <Offering>
              <div className="offering__image">
                <Image
                  fluid={image.cheatsheet.childImageSharp.fluid}
                  alt="Beautifully designed Logical Properties cheatsheet that will prompt you of each of the logical properties names, values, and syntax."
                />
              </div>
              <div className="offering__content-wrapper">
                <p className="offering__title">Logical Properties Cheatsheet</p>
                <p className="offering__subtitle">
                  Get prompted each of the Logical Properties names, values, and
                  syntax.
                </p>
                <Link
                  primary={true}
                  link={"https://store.cssacademy.com/logical-properties"}
                  title={"Learn More"}
                  color={"teal-light"}
                  arrow={"true"}
                />
              </div>
            </Offering>
            <Offering>
              <div className="offering__image">
                <Image
                  fluid={image.styles.childImageSharp.fluid}
                  alt="Get an inside look at the base styles that make up CSS Academy."
                />
              </div>
              <div className="offering__content-wrapper">
                <p className="offering__title">CSS Academy Base Styles</p>
                <p className="offering__subtitle">
                  Get a behind the scenes look at base styles and how they are
                  used throughout the site.
                </p>
                <Link
                  primary={true}
                  link={"https://store.cssacademy.com/css-academy-base-styles"}
                  title={"Learn More"}
                  color={"teal-light"}
                  arrow={"true"}
                />
              </div>
            </Offering>
          </div>
        </InsideWrapper>
      </Layout>
    </>
  )
}

export const TextWrapper = styled.div`
  --flow-space: ${perfectFourth[400]};
  padding-block-start: ${perfectFourth[600]};
  padding-block-end: ${perfectFourth[600]};
  @media ${breakpoint.md} {
    text-align: center;
  }
  p {
    font-size: ${typeScale.paragraph};
    line-height: 1.6;
  }
`
export const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 1rem;
`

export const Offering = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-block-end: ${perfectFourth[400]};
  .offering {
    &__image {
      width: 50%;
      border-radius: ${borderRadius.reg};
      box-shadow: ${boxShadow.reg};
      overflow: hidden;
    }
    &__content-wrapper {
      width: 90%;
      padding-inline-start: ${perfectFourth[600]};
      padding-inline-end: ${perfectFourth[800]};
      line-height: 1.2;
      color: ${navy[500]};
    }
    &__title {
      font-weight: bold;
      font-size: ${typeScale.header4};
      padding-block-end: ${perfectFourth[300]};
    }
    &__subtitle {
      font-size: ${typeScale.subtitle};
      line-height: 1.5;
      padding-block-end: ${perfectFourth[400]};
    }
    &__link {
      display: inline-block;
      margin-top: 0.5rem;
    }
  }
`

const getImages = graphql`
  {
    logical: file(relativePath: { eq: "logical-properties.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cheatsheet: file(
      relativePath: { eq: "logical-properties-cheatsheet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    styles: file(relativePath: { eq: "base-styles.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Academy
